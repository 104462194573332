import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'


Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta:'兴城人居智慧管理平台'
  },
  {
    path: '/index',
    // name: 'index',
    component: () => import('../views/Index.vue'),
    meta:'首页',
    children: [
      { path: '/', component: () => import('../views/Index_map.vue'), meta: '首页'},
      { path: '/index/Index_map', component: () => import('../views/Index_map1.vue'), meta: '项目分布'},
      { path: '/index/User_manage', component: () => import('../views/User_manage.vue'), meta: '用户管理'},
      { path: '/index/Organization_manage', component: () => import('../views/Organization_manage.vue'), meta: '组织管理'},
      { path: '/index/Permission_manage', component: () => import('../views/Permission_manage.vue'), meta: '权限管理'},
      { path: '/index/Menu_manage', component: () => import('../views/Menu_manage.vue'), meta: '菜单管理'},
      { path: '/index/Examine_manage', component: () => import('../views/Examine_manage.vue'), meta: '审核管理'},

      { path: '/index/Access_log', component: () => import('../views/Access_log.vue'), meta: '访问日志'},
      { path: '/index/Operation_log', component: () => import('../views/Operation_log.vue'), meta: '操作日志'},
      
      //库存
      { path: '/index/Device', component: () => import('../views/device/Device.vue'), meta: '设备列表'},
      { path: '/index/DeviceIn', component: () => import('../views/device/DeviceIn.vue'), meta: '设备入库'},
      { path: '/index/DeviceOut', component: () => import('../views/device/DeviceOut.vue'), meta: '设备出库'},

      //订单
      { path: '/index/Order_plan', component: () => import('../views/order/Order_plan.vue'), meta: '安装'},
      { path: '/index/Channel', component: () => import('../views/order/Channel.vue'), meta: '渠道'},
      { path: '/index/Business', component: () => import('../views/order/Business.vue'), meta: '商务'},
      { path: '/index/Repair', component: () => import('../views/order/Repair.vue'), meta: '维修'},
      { path: '/index/Operate', component: () => import('../views/order/Operate.vue'), meta: '运营'},
      { path: '/index/Peace', component: () => import('../views/order/Peace.vue'), meta: '安心'},

         //回款
      { path: '/index/Payment_collection', component: () => import('../views/payment/Payment_collection.vue'), meta: '回款管理'},
      { path: '/index/Billing_record', component: () => import('../views/payment/Billing_record.vue'), meta: '开票记录'},
      { path: '/index/Billing_info', component: () => import('../views/payment/Billing_info.vue'), meta: '开票信息'},
      { path: '/index/Bank_account', component: () => import('../views/payment/Bank_account.vue'), meta: '银行账户'},
      { path: '/index/Drawer', component: () => import('../views/payment/Drawer.vue'), meta: '出票单位'},
      { path: '/index/Invalid', component: () => import('../views/payment/Invalid.vue'), meta: '票据作废'},

       //采购
       { path: '/index/purchase', component: () => import('../views/purchase/Purchase.vue'), meta: '采购'},
       { path: '/index/Payment', component: () => import('../views/purchase/Payment.vue'), meta: '付款'},
       { path: '/index/Reimbursement', component: () => import('../views/purchase/Reimbursement.vue'), meta: '报销'},

      { path: '/index/Statistics', component: () => import('../views/Statistics.vue'), meta: '统计分析'},
      { path: '/index/Project', component: () => import('../views/Project.vue'), meta: '项目列表'},
      { path: '/index/Supplier', component: () => import('../views/Supplier.vue'), meta: '供应商'},
      { path: '/index/Agent', component: () => import('../views/Agent.vue'), meta: '代理商'},
      { path: '/index/Service', component: () => import('../views/Service.vue'), meta: '产品服务'},
      { path: '/index/warning_center', component: () => import('../views/Warning_center.vue'), meta: '预警中心'},
      { path: '/index/Operation_log', component: () => import('../views/Operation_log.vue'), meta: '操作日志'},
    ]
  },
]

const router = new VueRouter({
  routes
})





export default router
