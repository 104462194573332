import axios from 'axios';
import qs from 'qs';
import Utils from "@/utils/util.js";
import _this from '../main.js';
// import { logout } from '@/api/apis.js'



// axios.defaults.timeout = 20000
axios.defaults.withCredentials = true// 让ajax携带cookie，同时操作都可以交给后台

//请求(request)拦截器
axios.interceptors.request.use((req) => {
    if(req.url=='/admin/l/order'||req.url=='/admin/l/order/type'||req.url=='/admin/l/stock/apply/batch'
    ||req.url=='/admin/l/order/business'||req.url=='/admin/l/order/operation'||req.url=='/admin/l/order/distributor'
    ||req.url=='/admin/l/stock/apply/distributor/batch'||req.url=='/admin/l/stock/requisition'||req.url=='/admin/l/stock/use'
    ||req.url=='/admin/l/order/maintain'||req.url=='/admin/l/stock/apply/maintain/batch'||req.url=='/admin/l/order/renew'
    ||req.url=='/admin/l/statistics/dateorder'||req.url=='/admin/l/statistics/'||req.url=='/admin/l/statistics/material/'
    ||req.url=='/admin/l/order/orderanddistributor'||req.url=='/admin/l/statistics/cost'||req.url=='/admin/l/statistics/details'
    ||req.url=='/admin/l/order/operation/product'||req.url=='/admin/l/order/business/orderbusiness'||req.url=='/admin/l/order/maintain/ordermaintain'||req.url=='/admin/l/order/operation/orderoperation'||req.url=='/admin/l/order/renew/orderrenew'
    ||req.url=='/admin/l/record'||req.url=='/admin/l/order/business/unpay'||req.url=='/admin/l/statistics/ys'||req.url=='/admin/l/statistics/yw'
    ||req.url=='/admin/l/statistics/dlys'||req.url=='/admin/l/statistics/cl'||req.url=='/admin/l/statistics/ss'
    ){
        req.headers['Content-Type'] = 'application/json';
    }
    else if (req.method === 'post' || req.method === 'put') {
        req.data = qs.stringify(req.data)
    }
    // else{
    // 不然request 接收不到，配合qs
    // req.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    
    if (localStorage.getItem("p")!=undefined) {
        let token = localStorage.getItem("p")
        token=Utils.decrypt(token)
        req.headers['Authorization']=(token==null||token==undefined)?"":token
    } 
    return req;
}, err => {
    return Promise.reject(err)
});


//响应(response)拦截器
axios.interceptors.response.use((res) => {
    switch (res.data.code) {
        case 200:
            return res.data;
            case 400:
                _this.$router.push('/');
                // out()
                break;
                case 401:
                    _this.$router.push('/');
                // out()
                break;
                case 403:
                _this.$message({
                    message: '您暂无权限',
                    type: 'warning'
                  });
                break;
        default:
            return res;
    }
}, (error) => {
    // console.log(error.request);
    // if(error.request.timeout==20000){
    //     _this.$message('请求超时')
    // }
    Promise.reject(error)
});

// async function rf(d) {
//     let refreshToken = localStorage.getItem("rf")
//     let data = {
//         refreshToken
//     }
//     // let resData={}
//     let method = d.config.method
//     applyToken(data).then(res => {
//         var to = res.accessToken;
//         var rf = res.refreshToken;
//         localStorage.setItem('to', to)
//         localStorage.setItem('rf', rf)

//         //    axios[method](d.config.url,d.config.data).then(res=>{
//         //        flag=true
//         //     resData=res
//         //    })
//     })
//     return await axios[method](d.config.url, d.config.data)

// }
// function out() {
//     logout();
//     localStorage.removeItem("p");
//     localStorage.removeItem("perArr");
//     this.$router.push("/");
//   }


