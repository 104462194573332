import axios from 'axios';
import qs from 'qs';


const url =window.location.origin
// const url ='http://192.168.1.160:80/' /admin
// axios.defaults.baseURL = '/api';
axios.defaults.baseURL = url;

export const axiosUrl =window.location.origin

//操作日志
export const erpGetList = (data) => axios.post('/admin/l/erp/getList',data)
//访问日志
export const erpGetLoginList = (data) => axios.post('/admin/l/erp/getLoginList',data)
//清空日志
export const erpdropLogs = (data) => axios.post('/admin/l/erp/dropLogs',data)
//登陆
export const login = (data) => axios.post('/admin/l/user/doLoginByPc',data)

//统计页面库存预警排行 更多
export const inventoryWarnList = (data) => axios.post('/admin/l/product/inventoryWarnList',data)
//总页面统计分析之供应商应付查看更多
export const getMerchantProcureList= (data) => axios.post('/admin/l/statis/getMerchantProcureList',data)
//总页面统计分析之应收列表
export const ys= (data) => axios.post('/admin/l/statistics/ys',data)
//总页面统计分析之运维列表
export const yw= (data) => axios.post('/admin/l/statistics/yw',data)
//总页面统计分析之代理商应收
export const dlys= (data) => axios.post('/admin/l/statistics/dlys',data)
//总页面统计分析之材料消耗排行
export const cl= (data) => axios.post('/admin/l/statistics/cl',data)
//总页面统计分析之今日实施
export const ss= (data) => axios.post('/admin/l/statistics/ss',data)
//字典表
export const dtGetListByType= (data) => axios.post('/admin/l/dt/getListByType',data)


//获取验证码登录
export const getCaptcha = (data) => axios.post('/admin/l/user/sendCode',data)
//验证码登录
export const doLoginByPhone = (data) => axios.post('/admin/l/user/doLoginByPhone',data)
//获取验证码登录
export const dosth = () => axios.post('/admin/l/dosth')


//登陆后获取用户信息
export const getUserInfo = (data) => axios.post('/admin/l/user/getUserInfo',data)
//退出
export const logout = (data) => axios.post('/admin/l/user/loginOut',data)
//修改密码
export const editPwd = (data) => axios.post('/admin/l/user/editPwd',data)
//登陆后获取权限
export const queryAllPermission = (data) => axios.post('/admin/l/menu/queryAllPermission',data)

//重置密码
export const repwd = (data) => axios.post('/admin/l/user/repwd',data)


//选择人员
export const getUserByType = (data) => axios.post('/admin/l/department/getUserByType',data)
//首页地图
export const homeView = (data) => axios.post('/admin/l/sysOrg/homeView',data)
//首页日历
export const dateorder = (data) => axios.post('/admin/l/statistics/dateorder',data)
//查看详情
export const dateorderDetails = (data) => axios.post('/admin/l/statistics/details',data)

//++产品与服务
//产品列表
export const productGetList= (data) => axios.post('/admin/l/product/getList',data)
//保存产品
export const saveProduct= (data) => axios.post('/admin/l/product/save',data)
//修改产品
export const deleteProduct= (data) => axios.post('/admin/l/product/delete',data)
//查看组合包
export const getAssembleInfo= (data) => axios.post('/admin/l/product/getAssembleInfo',data)
//商户列表
export const merchantGetList= (data) => axios.post('/admin/l/merchant/getList',data)
//保存商户
export const saveMerchant= (data) => axios.post('/admin/l/merchant/save',data)
//修改商户
export const deleteMerchant= (data) => axios.post('/admin/l/merchant/delete',data)

//++设备
//设备列表
export const deviceStoreGetList= (data) => axios.post('/admin/l/deviceStore/getList',data)
//入库列表
export const deviceInGetList= (data) => axios.post('/admin/l/deviceIn/getList',data)
//出库列表
export const deviceOutGetList= (data) => axios.post('/admin/l/deviceOut/getList',data)

//++采购

//采购列表
export const procureGetList= (data) => axios.post('/admin/l/procure/getList',data)
//采购详情
export const procureInfo1= (data) => axios.post('/admin/l/procure/info',data)
export const procureInfo= (data) => axios.post('/admin/l/procure/new/info',data)
//采购上传合同
export const procureFillContractFile= (data) => axios.post('/admin/l/procure/fillContractFile',data)
//采购新增
export const procureAdd= (data) => axios.post('/admin/l/procure/add',data)
//采购审批
export const procureExamine= (data) => axios.post('/admin/l/procure/examine',data)
//采购付款完成（先货后款）
export const fulfilPay= (data) => axios.post('/admin/l/pay/fulfilPay',data)

//++作废
//获取申请作废列表
export const acGetList= (data) => axios.post('/admin/l/ac/getList',data)
//获取申请作废列表
export const getMainList= (data) => axios.post('/admin/l/ac/getMainList',data)
//获取申请作废详情
export const acInfo= (data) => axios.post('/admin/l/ac/info',data)
//新增
export const acAdd= (data) => axios.post('/admin/l/ac/add',data)
//审核
export const acExamine= (data) => axios.post('/admin/l/ac/examine',data)

//++付款
//付款列表
export const paymentGetList= (data) => axios.post('/admin/l/payment/getList',data)
//新增付款
export const paymentAdd= (data) => axios.post('/admin/l/payment/add',data)
//付款上传发票
export const fillInvoiceAnnex= (data) => axios.post('/admin/l/payment/fillInvoiceAnnex',data)
//付款详情
export const paymentInfo= (data) => axios.post('/admin/l/payment/info',data)
//付款审核
export const paymentExamine= (data) => axios.post('/admin/l/payment/examine',data)
//采购计划付款获取采购计划列表
export const getListByProcure= (data) => axios.post('/admin/l/procure/getListByProcure',data)
//供应商付款获取采购计划列表并计算尾款
export const getListByMerchant= (data) => axios.post('/admin/l/procure/getListByMerchant',data)

//++报销
//报销列表
export const rmbGetList= (data) => axios.post('/admin/l/rmb/getList',data)
//新增报销
export const rmbAdd= (data) => axios.post('/admin/l/rmb/add',data)
//报销详情
export const rmbInfo= (data) => axios.post('/admin/l/rmb/info',data)
//报销详情
export const rmbExamine= (data) => axios.post('/admin/l/rmb/examine',data)


//支付
export const payAdd= (data) => axios.post('/admin/l/pay/add',data)
//银行账户
export const getListByPay= (data) => axios.post('/admin/l/ba/getListByPay',data)
//++安装
//安装列表
export const orderGetList= (data) => axios.post('/admin/l/order',data)
//新增
export const orderAdd= (data) => axios.put('/admin/l/order',data)
//判断计划类型
export const orderType= (data) => axios.post('/admin/l/order/type',data)
//查看
export const orderGet= (data) => axios.get('/admin/l/order/'+data)
//审核
export const orderExamine= (data) => axios.put('/admin/l/order',data)
//申领
export const applyBatch= (data) => axios.put('/admin/l/stock/apply/batch',data)

//查看出库清单
export const stockRequisition= (data) => axios.post('/admin/l/stock/requisition',data)
//查看实际使用清单
export const stockUse= (data) => axios.post('/admin/l/stock/use',data)

//删除 安装
export const orderDelete= (data) => axios.delete('/admin/l/order/'+data)
//删除 商务
export const businessDelete= (data) => axios.delete('/admin/l/order/business/'+data)
//删除 渠道
export const distributorDelete= (data) => axios.delete('/admin/l/order/distributor/'+data)
//删除 维修
export const maintainDelete= (data) => axios.delete('/admin/l/order/maintain/'+data)
//删除 运营
export const operationDelete= (data) => axios.delete('/admin/l/order/operation/'+data)
//删除 安心
export const renewDelete= (data) => axios.delete('/admin/l/order/renew/'+data)

//++维修
//维修列表
export const maintainGetList= (data) => axios.post('/admin/l/order/maintain',data)
export const maintainAdd= (data) => axios.put('/admin/l/order/maintain',data)
//查看
export const maintainGet= (data) => axios.get('/admin/l/order/maintain/'+data)
//申领
export const maintainBatch= (data) => axios.put('/admin/l/stock/apply/maintain/batch',data)

//获取项目已安装的产品列表
export const productget= (data) => axios.post('/admin/l/order/operation/product',data)

//++商务
//商务列表
export const businessGetList= (data) => axios.post('/admin/l/order/business',data)
export const businessGetList1= (data) => axios.post('/admin/l/order/business/unpay',data)
export const businessAdd= (data) => axios.put('/admin/l/order/business',data)
//查看
export const businessGet= (data) => axios.get('/admin/l/order/business/'+data)

//++渠道
//渠道列表
export const distributorGetList= (data) => axios.post('/admin/l/order/distributor',data)
export const distributorNoAdd= (data) => axios.put('/admin/l/order/distributor',data)
//查看
export const distributorNoGet= (data) => axios.get('/admin/l/order/distributor/'+data)
//申领
export const distributorNoBatch= (data) => axios.put('/admin/l/stock/apply/distributor/batch',data)

//++运营
//运营列表
export const operationGetList= (data) => axios.post('/admin/l/order/operation',data)
export const operationAdd= (data) => axios.put('/admin/l/order/operation',data)
//查看
export const operationGet= (data) => axios.get('/admin/l/order/operation/'+data)

//++安心
//安心列表
export const renewGetList= (data) => axios.post('/admin/l/order/renew',data)
export const renewAdd= (data) => axios.put('/admin/l/order/renew',data)
//查看
export const renewGet= (data) => axios.get('/admin/l/order/renew/'+data)

//++项目
//项目列表
export const wxGetOrgList1= (data) => axios.post('/admin/l/sysOrg/getOrgListByPc',data)
export const wxGetOrgList= (data) => axios.post('/admin/l/sysOrg/wxGetOrgList',data)
//新增项目
export const addSysOrgByWx= (data) => axios.post('/admin/l/sysOrg/addSysOrgByWx',data)
//项目详情
export const wxGetOrgInfo= (data) => axios.post('/admin/l/sysOrg/wxGetOrgInfo',data)
//代理商
export const getSupplierList= (data) => axios.post('/admin/l/merchant/getSupplierList',data)
//回款进度
export const getProgressInfo= (data) => axios.post('/admin/l/pq/getProgressInfo',data)
//项目查看合同详情
export const projectStatistics= (data) => axios.get('/admin/l/statistics/'+data)
//修改项目
export const sysOrgEdit= (data) => axios.post('/admin/l/sysOrg/edit',data)
//项目删除
export const sysOrgDel= (data) => axios.get('/admin/l/sysOrg/del?'+qs.stringify(data))
//项目查看材料统计
export const projectStatisticsMateria= (data) => axios.get('/admin/l/statistics/material/'+data)
//项目查看安装计划
export const orderanddistributor= (data) => axios.post('/admin/l/order/orderanddistributor',data)
//项目查看商务计划
export const orderbusiness= (data) => axios.post('/admin/l/order/business/orderbusiness',data)
//项目查看维修计划
export const ordermaintain= (data) => axios.post('/admin/l/order/maintain/ordermaintain',data)
//项目查看运营计划
export const orderoperation= (data) => axios.post('/admin/l/order/operation/orderoperation',data)
//项目查看安心计划
export const orderrenew= (data) => axios.post('/admin/l/order/renew/orderrenew',data)
//项目查看成本核算
export const cost= (data) => axios.get('/admin/l/statistics/cost/'+data)
//项目查看往来记录
export const recordList= (data) => axios.post('/admin/l/record',data)
//项目新增往来记录
export const recordAdd= (data) => axios.put('/admin/l/record',data)
//统计分析
export const totalAnalysis= (data) => axios.post('/admin/l/statis/totalAnalysis',data)
export const totalstatistics= (data) => axios.post('/admin/l/statistics',data)



//++回款
//回款列表
export const repaymentGetList= (data) => axios.post('/admin/l/repayment/getList',data)
//新增回款
export const repaymentAdd= (data) => axios.post('/admin/l/repayment/add',data)
//回款详情
export const repaymentInfo= (data) => axios.post('/admin/l/repayment/info',data)
//回款审核
export const repaymentExamine= (data) => axios.post('/admin/l/repayment/examine',data)

//++开票
//开票列表
export const invoiceGetList= (data) => axios.post('/admin/l/invoice/getList',data)
//新增开票
export const invoiceAdd= (data) => axios.post('/admin/l/invoice/add',data)
//开票详情
export const invoiceInfo= (data) => axios.post('/admin/l/invoice/info',data)
//根据项目或代理商获取开票信息
export const findById= (data) => axios.post('/admin/l/ii/findById',data)
//获取出票单位列表
export const getIssueTicket= (data) => axios.post('/admin/l/ii/getIssueTicket',data)
//审核开票计划
export const invoiceExamine= (data) => axios.post('/admin/l/invoice/examine',data)
//开票信息列表
export const iiGetList= (data) => axios.post('/admin/l/ii/getList',data)
//开票
export const iiSave= (data) => axios.post('/admin/l/ii/save',data)


//银行账户
export const baGetList= (data) => axios.post('/admin/l/ba/getList',data)
//保存银行账户
export const baSave= (data) => axios.post('/admin/l/ba/save',data)
//删除银行账户
export const baDelete= (data) => axios.post('/admin/l/ba/delete',data)

//出票信息
export const getIssueTicketList= (data) => axios.post('/admin/l/ii/getIssueTicketList',data)
//保存出票信息
export const saveIssueTicket= (data) => axios.post('/admin/l/ii/saveIssueTicket',data)
//删除出票信息
export const iiDelete= (data) => axios.post('/admin/l/ii/delete',data)


//项目总数  视频在线数/总数
export const getTotal = (data) => axios.post('/admin/l/device/getTotal',data)
//获取视频播放页面项目 在线率 视频列表数据
export const deviceGetList = (data) => axios.post('/admin/l/device/getList',data)
//新增设备
export const deviceAdd = (data) => axios.post('/admin/l/device/add',data)
//获取项目列表
export const getProjectList = (data) => axios.post('/admin/l/device/getProjectList',data)
//获取设备管理 视频列表
export const getDeviceList = (data) => axios.post('/admin/l/device/getDeviceList',data)
//阐述谁鄂毕
export const deviceDelete = (data) => axios.post('/admin/l/device/delete',data)
//编辑
export const updateDevice = (data) => axios.post('/admin/l/device/updateDevice',data)
//抓图
export const grabImages = (data) => axios.post('/admin/l/device/capture',data)

//获取业务员
export const getUserList = (data) => axios.post('/admin/l/sysOrg/getUserList',data)
//修改备注
export const sysOrgUpdate = (data) => axios.post('/admin/l/sysOrg/update',data)
//查看生命周期
export const inAndOutRecord = (data) => axios.post('/admin/l/deviceStore/inAndOutRecord',data)
//组织框架
export const getAll = (data) => axios.post('/admin/l/sysOrg/getAll',data)
//新增组织框架
export const sysOrgSave = (data) => axios.post('/admin/l/sysOrg/save',data)
//获取组织框架 下的组织
export const sysOrgGetOrgList = (data) => axios.post('/admin/l/sysOrg/getOrgList',data)
//删除组织框架组织
export const sysOrgGetOrDel = (data) => axios.post('/admin/l/sysOrg/del',data)
//id查组织详情
export const sysOrgGetOrGet = (data) => axios.post('/admin/l/sysOrg/get',data)

//获取组织下的用户集合
export const userGetAll = (data) => axios.post('/admin/l/user/getAll',data)
//获取组织下的用户集合  加搜索条件
export const userGetCurry = (data) => axios.post('/admin/l/user/queryUserInfoByOrgId',data)
//新增用户
export const userAdd = (data) => axios.post('/admin/l/user/add',data)
//查看用户详情
export const getUserById = (data) => axios.post('/admin/l/user/getUserById',data)
//修改用户
export const userEdit = (data) => axios.post('/admin/l/user/edit',data)
//删除用户
export const userdel = (data) => axios.post('/admin/l/user/del',data)
//验证电话号码
export const authPhone = (data) => axios.post('/admin/l/user/authPhone',data)

//获取菜单(登陆成功)
export const loginedAumenu = (data) => axios.post('/admin/l/menu/getMenu',data)

//获取菜单(所有)
export const getMenuAll = (data) => axios.post('/admin/l/menu/getMenuAll',data)
//新增菜单
export const saveMenu = (data) => axios.post('/admin/l/menu/save',data)
//获取菜单详情
export const queryMenuById = (data) => axios.post('/admin/l/menu/queryMenuById',data)
//修改菜单
export const menuEdit = (data) => axios.post('/admin/l/menu/edit',data)
//删除菜单
export const menuDel = (data) => axios.post('/admin/l/menu/del',data)

//查询角色
export const roleGetList = (data) => axios.post('/admin/l/role/getList',data)
//新增角色
export const roleSave = (data) => axios.post('/admin/l/role/save',data)
//角色与权限菜单绑定
export const saveRoleAndMenu = (data) => axios.post('/admin/l/middle/saveRoleAndMenu',data)
//根据角色id查询菜单与权限
export const queryMenuByRoleId = (data) => axios.post('/admin/l/middle/queryMenuByRoleId',data)
//删除角色并删除管理菜单和对应用户角色置空
export const roleDel = (data) => axios.post('/admin/l/role/del',data)

//审核列表
export const getDepartmentList = (data) => axios.post('/admin/l/department/getDepartmentList',data)
//设置人员
export const setDepartmentUsers = (data) => axios.post('/admin/l/department/setDepartmentUsers',data)
//新增
export const addDepartment = (data) => axios.post('/admin/l/department/addDepartment',data)
//新增
export const delDepartment = (data) => axios.post('/admin/l/department/delDepartment',data)